<template>
    <div class="buddha-detail" v-if="info">
        <div class="other-title">登记信息</div>
        <div class="buddha-detail-list">
            <div class="buddha-detail-item">
                <div class="buddha-detail-label">提交时间</div>
                <div class="buddha-detail-text">{{info.create_time}}</div>
            </div>
            <div class="buddha-detail-item">
                <div class="buddha-detail-label">提交方式</div>
                <div class="buddha-detail-text">{{info.wayText}}</div>
            </div>
            <div class="buddha-detail-item" v-if="info.admin_id">
                <div class="buddha-detail-label">提交人</div>
                <div class="buddha-detail-text">
                    <open-data type="userName" :openid="info.admin_id" v-if="isNaN(info.admin_id * 1)"/>
                    <span v-else>小程序端登记</span>
                </div>
            </div>
            <div class="buddha-detail-item" v-if="info.user_id">
                <div class="buddha-detail-label">经手人</div>
                <div class="buddha-detail-text">
                    <open-data type="userName" :openid="info.user_id" />
                </div>
            </div>
            <div class="buddha-detail-item">
                <div class="buddha-detail-label">名称</div>
                <div class="buddha-detail-text">{{info.tempName}}</div>
            </div>
            <div class="buddha-detail-item" v-if="info.typeName">
                <div class="buddha-detail-label">类别</div>
                <div class="buddha-detail-text">{{info.typeName}}</div>
            </div>
            <div class="buddha-detail-item" v-if="info.userTypeText">
                <div class="buddha-detail-label">对象</div>
                <div class="buddha-detail-text">{{info.userTypeText}}</div>
            </div>
            <template v-if="info.temp.fs_type == 2">
                <div class="buddha-detail-item">
                    <div class="buddha-detail-label">诵经人数</div>
                    <div class="buddha-detail-text">{{info.number + (info.extraNumber || 0)}}人{{info.extraNumber > 0 ? `（另加${info.extraNumber}人）` : ''}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.scripture">
                    <div class="buddha-detail-label">礼忏经书</div>
                    <div class="buddha-detail-text">{{info.scripture}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.chanting">
                    <div class="buddha-detail-label">诵经经书</div>
                    <div class="buddha-detail-text">{{info.chanting}}</div>
                </div>
            </template>
            <div class="buddha-detail-item" v-else-if="info.temp.fs_type == 3 && info.number">
                <div class="buddha-detail-label">诵经人数</div>
                <div class="buddha-detail-text">{{info.number}}人</div>
            </div>
            <template v-if="info.tablet_info">
                <template v-if="admin">
                    <div class="buddha-detail-item">
                        <div class="buddha-detail-label">牌位信息</div>
                        <div class="buddha-detail-text">
                            <buddha-tablet :value="info.tablet" :temp="info.temp" :edit="false" v-if="info.tablet && info.tablet.length > 0"></buddha-tablet>
                            <span v-else>未填写</span>
                        </div>
                    </div>
                    <div class="buddha-detail-item">
                        <div class="buddha-detail-label">价格</div>
                        <div class="buddha-detail-text">¥{{info.priceNum}}</div>
                    </div>
                </template>
                <div class="buddha-detail-item" v-if="info.kp_user_id">
                    <div class="buddha-detail-label">开牌法师</div>
                    <div class="buddha-detail-text">
                        <open-data type="userName" :openid="info.kp_user_id"/>
                    </div>
                </div>
                <div class="buddha-detail-item" v-if="info.palaceInfo">
                    <div class="buddha-detail-label">殿堂</div>
                    <div class="buddha-detail-text">{{info.palaceInfo.name}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.showDate">
                    <div class="buddha-detail-label">佛事日期</div>
                    <div class="buddha-detail-text">{{info.showDate}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.showTime">
                    <div class="buddha-detail-label">佛事时间</div>
                    <div class="buddha-detail-text">{{info.showTime}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.needOpen && info.master && info.master.length > 0">
                    <div class="buddha-detail-label">开牌单</div>
                    <div class="buddha-detail-text">
                        <div class="buddha-open-tablet">
                            <open-tablet ref="openTablet" :buddhism="info"></open-tablet>
                        </div>
                    </div>
                </div>
                <div class="buddha-detail-item" v-if="info.note">
                    <div class="buddha-detail-label">{{info.needOpen ? '开牌备注' : '派单备注'}}</div>
                    <div class="buddha-detail-text">{{info.note}}</div>
                </div>
            </template>
            <div class="buddha-detail-item" v-else-if="admin">
                <div class="buddha-detail-label">金额</div>
                <div class="buddha-detail-text">¥{{info.priceNum}}</div>
            </div>
            <template v-if="info.temp && [1, 3].includes(info.temp.cat)">
                <div class="buddha-detail-item" v-if="admin">
                    <div class="buddha-detail-label">联系人</div>
                    <div class="buddha-detail-text">{{info.contact_person || '无'}}</div>
                </div>
                <div class="buddha-detail-item" v-if="admin">
                    <div class="buddha-detail-label">联系电话</div>
                    <div class="buddha-detail-text">{{info.tel || '无'}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.temp && [2, 3].includes(info.temp.fs_type)">
                    <div class="buddha-detail-label">府上名称</div>
                    <div class="buddha-detail-text">{{(info.username || "无")}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.remark">
                    <div class="buddha-detail-label">祈福信息</div>
                    <div class="buddha-detail-text">{{info.remark}}</div>
                </div>
                <div class="buddha-detail-item" v-if="info.temp && info.temp.fs_type > 12">
                    <div class="buddha-detail-label">登记备注</div>
                    <div class="buddha-detail-text">{{info.commit_note || '无'}}</div>
                </div>
            </template>
            <template v-if="info.admin_id && admin">
                <div class="buddha-detail-item">
                    <div class="buddha-detail-label">票据</div>
                    <div class="buddha-detail-text">
                        <div class="file-list" v-if="info.bill">
                            <img class="file-item" :src="f" v-for="(f, i) in info.bill" :key="i" @click="previewImg(i)">
                        </div>
                        <div v-else>无</div>
                    </div>
                </div>
                <div class="buddha-detail-item">
                    <div class="buddha-detail-label">票据备注</div>
                    <div class="buddha-detail-text">{{info.bill_note || '无'}}</div>
                </div>
            </template>
        </div>
        <template v-if="info.order && admin">
            <div class="other-title">订单状态</div>
            <div class="buddha-detail-list">
                <div class="buddha-detail-item">
                    <div class="buddha-detail-label">订单状态</div>
                    <div class="buddha-detail-text">{{info.order.statusText}}</div>
                </div>
                <div class="buddha-detail-item">
                    <div class="buddha-detail-label">订单号</div>
                    <div class="buddha-detail-text">{{info.order.order_num}}</div>
                </div>
                <div class="buddha-detail-item">
                    <div class="buddha-detail-label">下单时间</div>
                    <div class="buddha-detail-text">{{info.order.create_time}}</div>
                </div>
                <div class="buddha-detail-item">
                    <div class="buddha-detail-label">订单金额</div>
                    <div class="buddha-detail-text">¥{{(info.order.price / 100).toFixed(2)}}</div>
                </div>
            </div>
        </template>
        <div class="flex-box align-center other-title">佛事状态 <div class="buddha-detail-text">{{info.statusText}}</div></div>
        <a-timeline class="buddha-detail-list" style="padding-top: 20px">
            <a-timeline-item v-for="(f, i) in info.flowList" :key="i">
                <a-space class="buddha-operate-item" :class="{'buddha-operate-cancel': f.type == 99}">
                    <span>{{f.time}}</span>
                    <span v-if="f.type == 1 || f.type == 99">经手人</span>
                    <template v-if="f.user_id">
                        <open-data type="userName" :openid="f.user_id" v-if="isNaN(f.user_id * 1)"/>
                        <span v-else>小程序端登记</span>
                    </template>
                    <span v-else>小程序用户提交</span>
                    <span>{{f.title}}</span>
                    <span v-if="f.type == 99">取消原因：{{info.cancel_reason || '无'}}</span>
                </a-space>
                <div class="buddha-operate-note" v-if="f.type == 7"><span class="operate-note-warning">驳回原因：</span>{{f.reason}}</div>
            </a-timeline-item>
        </a-timeline>
    </div>
</template>

<script>
    import {getLunarDay} from "../components/calendar/lunar";
    import * as buddhism from "../common/buddha/buddhism";
    import OpenTablet from "./OpenTablet";
    import buddhaTablet from "../components/buddha/buddha-tablet";
    import {isBuddhaAdmin} from "../common/constant/org";

    export default {
        name: "BuddhaDetail",
        props: {
            info: Object
        },
        components: {
            OpenTablet,
            buddhaTablet,
        },
        data() {
            return {
                buddhism,
                admin: isBuddhaAdmin()
            }
        },
        methods: {
            getLunarDay,
            previewImg(i) {
                let list = this.info.bill;
                const current = list[i];
                const urls = list.map(v => {
                    return {
                        url: v,
                        title: '票据'
                    }
                });
                this.$store.dispatch("showImgList", urls, current);
            },
        }
    }
</script>

<style scoped lang="less">
    .buddha-detail {
        position: relative;
    }
    .buddha-detail-list {
        padding: 0 40px 20px;
    }
    .buddha-detail-item {
        display: flex;
        padding: 4px 0;
        line-height: 1.5;
        font-size: 16px;
        .buddha-detail-item {
            margin-bottom: 8px;
            padding: 0;
        }
    }
    .buddha-detail-label {
        min-width: 120px;
        color: @text-color-secondary;
    }
    .buddha-detail-text {
        flex: 1;
        margin-left: 20px;
        color: @text-color;
        text-align: justify;
    }
    .other-title .buddha-detail-text {
        font-weight: normal;
        font-size: 16px;
    }
    .text-item-span {
        margin-right: 1em;
    }
    .buddha-open-tablet {
        width: 560px;
    }
    .buddha-tablet {
        position: absolute;
        top: 0;
        right: 20px;
        width: 232px;
        height: 667px;
    }
    .buddha-tablet-img {
        transform-origin: 0 0;
        transform: scale(0.5);
    }
    .buddha-operate-note {
        margin-top: 6px;
        color: @text-color-secondary;
    }
    .operate-note-warning {
        color: @error-color;
    }
    .buddha-operate-cancel {
        color: @error-color;
    }
</style>
